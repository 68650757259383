const DOMAIN_NAME = process.env.REACT_APP_BACKEND_DOMAIN_NAME;
export const GET_POSTS_BASE_URL = `${DOMAIN_NAME}/api/v1/updeeds/admin/posts`;
export const GET_USERS_BASE_URL = `${DOMAIN_NAME}/api/v1/updeeds/admin/users`;
export const GET_CAMPAIGNS_BASE_URL = `${DOMAIN_NAME}/api/v1/updeeds/admin/campaigns`;
export const GET_SINGLE_POST_BASE_URL = `${DOMAIN_NAME}/api/v1/updeeds/admin/post`;
export const GET_SINGLE_USER_BASE_URL = `${DOMAIN_NAME}/api/v1/updeeds/admin/user`;
export const GET_SINGLE_CAMPAIGN_BASE_URL = `${DOMAIN_NAME}/api/v1/updeeds/admin/campaign`;
//POST ACTIONS URL
export const MARK_POST_AS_GRATITUDE_BASE_URL = `${DOMAIN_NAME}/api/v1/updeeds/admin/markPostGratitude`;
export const MARK_POST_AS_FLAG_BASE_URL = `${DOMAIN_NAME}/api/v1/updeeds/admin/flagPost`;
export const MARK_USER_AS_FLAG_BASE_URL = `${DOMAIN_NAME}/api/v1/updeeds/admin/flagUser`;
export const MARK_USER_AS_AMAZING_BASE_URL = `${DOMAIN_NAME}/api/v1/updeeds/admin/markUserAmazing`;
export const PROMOTE_POST_BASE_URL = `${DOMAIN_NAME}/api/v1/updeeds/admin/post/##id##/promote`;
export const UNPROMOTE_POST_BASE_URL = `${DOMAIN_NAME}/api/v1/updeeds/admin/post/##id##/unpromote`;

//campaign
export const APPROVE_CAMPAIGN_BASE_URL = `${DOMAIN_NAME}/api/v1/updeeds/admin/campaign/##id##/approve`;
export const REJECT_CAPMPAIGN_BASE_URL = `${DOMAIN_NAME}/api/v1/updeeds/admin/campaign/##id##/reject`;
export const PROMOTE_CAPMPAIGN_BASE_URL = `${DOMAIN_NAME}/api/v1/updeeds/admin/campaign/##id##/promote`;
export const UNPROMOTE_CAPMPAIGN_BASE_URL = `${DOMAIN_NAME}/api/v1/updeeds/admin/campaign/##id##/unpromote`;

//user
export const VERIFY_USER_BASE_URL = `${DOMAIN_NAME}/api/v1/updeeds/admin/user/##id##/verify`;
