import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { PROMOTE_POST_BASE_URL } from '../../../APIEndPoints';
import _ from 'lodash';

export default function PromotePostModal({ postId, closePromotePostModal, updatePromoteFieldInPost }) {
  const token = localStorage.getItem('userToken');

  const [formData, setFormData] = useState({
    title: '',
    linkUrl: '',
    promotedUnder: '',
    featuredAtTS: '',
    expiresAtTS: ''
  });

  const [error, setError] = useState('');

  const handleChange = e => {
    e.persist();
    // console.log(e.target.name, e.target.value);
    setFormData(prev => {
      return {
        ...prev,
        [e?.target?.name]: e?.target?.value
      };
    });
  };

  const promotePost = async e => {
    e.preventDefault();
    setError('');
    const { data: response } = await axios.post(
      PROMOTE_POST_BASE_URL.replace('##id##', postId),
      {
        ...formData,
        promotedUnder: formData?.promotedUnder?.split(','),
        featuredAtTS: new Date(formData?.featuredAtTS).valueOf(),
        expiresAtTS: new Date(formData?.expiresAtTS).valueOf()
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    if (response.status === 200) {
      updatePromoteFieldInPost();
      closePromotePostModal();
    } else {
      console.log(response);
      if (typeof response.error === 'string') {
        setError(response.error);
      } else {
        setError('error Posting data');
      }
    }
  };

  return (
    <div
      //   className="w-screen h-screen fixed top-0 left-0 bg-black bg-opacity-50 flex items-center justify-center z-20"
      style={{
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: '0',
        left: '0',
        background: '#00000080',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: '20'
      }}
    >
      <div
        // className="max-h-[90vh] overflow-y-auto scroll bg-white rounded-lg  relative shadow-sd3"
        style={{
          maxHeight: '90vh',
          overflowY: 'auto',
          background: '#ffffff',
          borderRadius: '8px',
          position: 'relative',
          boxShadow: '-12px 12px 24px 16px rgba(0,0,0,0.16)'
        }}
      >
        <div
          style={{
            width: '100%',
            textAlign: 'center',
            fontSize: '20px',
            fontWeight: '500',
            marginTop: '40px'
          }}
        >
          Promote Post
        </div>

        <form
          onSubmit={promotePost}
          className=""
          style={{
            width: '500px',
            maxWidth: '100%',
            margin: ' 30px auto',
            padding: '0 30px'
          }}
        >
          <div style={{ width: '100%', margin: '10px 0 0' }}>
            <p style={{ marginBottom: '5px' }}>Title</p>
            <input
              name="title"
              style={{
                width: '100%',
                background: '#f2f2f2',
                border: '1px solid #757575',
                borderRadius: '4px',
                padding: '4px'
              }}
              id="title"
              type="text"
              value={formData?.title}
              onChange={handleChange}
              required
            />
          </div>

          <div style={{ width: '100%', margin: '10px 0 0' }}>
            <p style={{ marginBottom: '5px' }}>linkUrl</p>
            <input
              name="linkUrl"
              id="linkUrl"
              type="text"
              style={{
                width: '100%',
                background: '#f2f2f2',
                border: '1px solid #757575',
                borderRadius: '4px',
                padding: '4px'
              }}
              value={formData?.linkUrl}
              onChange={handleChange}
              required
            />
          </div>

          <div style={{ width: '100%', margin: '10px 0 0' }}>
            <p style={{ marginBottom: '5px' }}>Promoted Under</p>
            <input
              style={{
                width: '100%',
                background: '#f2f2f2',
                border: '1px solid #757575',
                borderRadius: '4px',
                padding: '4px'
              }}
              name="promotedUnder"
              id="promotedUnder"
              type="text"
              placeholder="comma seperated words"
              value={formData?.promotedUnder}
              onChange={handleChange}
              required
            />

            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                gap: '20px'
              }}
            >
              <div style={{ width: '100%', margin: '10px 0 0' }}>
                <p style={{ marginBottom: '5px' }}>Starts At</p>
                <input
                  name="featuredAtTS"
                  type="date"
                  id="featuredAtTS"
                  onChange={handleChange}
                  value={formData?.featuredAtTS}
                  style={{
                    background: '#f2f2f2',
                    border: '1px solid #757575',
                    borderRadius: '4px',
                    padding: '4px'
                  }}
                  required
                />
              </div>

              <div style={{ width: '100%', margin: '10px 0 0' }}>
                <p style={{ marginBottom: '5px' }}>Ends At</p>
                <input
                  name="expiresAtTS"
                  type="date"
                  id="expiresAtTS"
                  onChange={handleChange}
                  value={formData?.expiresAtTS}
                  style={{
                    background: '#f2f2f2',
                    border: '1px solid #757575',
                    borderRadius: '4px',
                    padding: '4px'
                  }}
                  required
                />
              </div>
            </div>

            <div
              style={{
                height: '20px',
                marginTop: '5px',
                width: '100%',
                textAlign: 'center',
                fontSize: '12px',
                color: '#eb5757'
              }}
            >
              {error}
            </div>

            <div
              className=""
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '20px',
                marginTop: '30px'
              }}
            >
              <button onClick={closePromotePostModal} className="btn btn-secondary">
                close
              </button>
              <button type="submit" className=' btn btn-primary btn-block"'>
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
