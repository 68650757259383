import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../context/Context';
import { auth } from '../firebase';
import { toast } from 'react-toastify';
import jwt_decode from 'jwt-decode';
import moment from 'moment';

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthContextProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(false);
  const [loginError, setLoginError] = useState('');
  const [loading, setLoading] = useState(true);

  const login = async (email, password) => {
    try {
      const res = await auth.signInWithEmailAndPassword(email, password);
      const userToken = await res.user.getIdToken();
      localStorage.setItem('userToken', userToken);
      localStorage.setItem('user', email);
      toast.success(`Logged in as ${email}`);
      console.log(res);
      return true;
    } catch (error) {
      setLoginError(error.message);
    }
  };

  const logout = async () => {
    try {
      await auth.signOut();
      localStorage.removeItem('userToken');
      localStorage.removeItem('user');
    } catch (error) {
      console.log(error.message);
    }
  };

  const verifyToken = token => {
    if (!token) return false;
    let decodedToken = jwt_decode(token);
    let now = moment(new Date());
    let expiry = moment(new Date(decodedToken.exp * 1000));

    if (now.isAfter(expiry)) {
      localStorage.removeItem('userToken');
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    const unsubscibe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscibe;
  }, []);

  const value = {
    currentUser,
    setCurrentUser,
    loginError,
    setLoginError,
    login,
    logout,
    verifyToken
  };

  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
}
