import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, Dropdown, Button } from 'reactstrap';
// import team3 from '../../assets/img/team/3.jpg';
// import Avatar from '../common/Avatar';
import { useAuth } from '../../layouts/AuthContextProvider';

const ProfileDropdown = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const { logout } = useAuth();
  const user = localStorage.getItem('user');
  return (
    <Dropdown
      nav
      inNavbar
      isOpen={dropdownOpen}
      toggle={toggle}
      onMouseOver={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setDropdownOpen(true);
      }}
      onMouseLeave={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setDropdownOpen(false);
      }}
    >
      <DropdownToggle nav className="pr-0">
        <Button color="primary" block className="mt-2">{`${user}`}</Button>
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-card">
        <div className="bg-white rounded-soft py-2">
          {/* <DropdownItem tag={Link} to="/pages/settings">
            Settings
          </DropdownItem> */}
          <DropdownItem onClick={logout} tag={Link} to="/login">
            Logout
          </DropdownItem>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default ProfileDropdown;
