import React, { Fragment, createRef, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Button, Col, Row, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Link } from 'react-router-dom';
import PostsTableActions from './PostsTableActions';
import moment from 'moment';
import PostReportsModal from './PostReportsModal';
import ClapCountModal from './ClapCountModal';
import RecommendedModal from './RecommendedModal';
import PostStatus from './PostStatus';

const CustomTotal = ({ currentPage, lastPage }) => (
  <span>
    Page {currentPage} of {lastPage}
  </span>
);

const dateFormatter = date => <Fragment>{date == null ? '-' : moment(date).format('DD-MM-YYYY hh:mm A')}</Fragment>;

const moderatedByFormatter = moderatedBy => <span>{moderatedBy ? moderatedBy : '-'}</span>;

const modalStyles = { cursor: 'pointer', color: '#2C7BE5' };

const modalFormatter = link => (
  <div className="font-weight-semi-bold" style={modalStyles}>
    {link ? link : '0'}
  </div>
);

const clapCountFormatter = claps => {
  return (
    <div className="font-weight-semi-bold" style={modalStyles}>
      {claps ? claps : '0'}
    </div>
  );
};

const nameFormatter = (dataField, { postedBy }) => (
  <Fragment>
    {postedBy.firstName} {postedBy.lastName}
  </Fragment>
);

const PostsTable = ({
  setToggleOnAction,
  toggleOnAction,
  posts,
  setPosts,
  skip,
  take,
  currentPage,
  lastPage,
  handleFirst,
  handlePrev,
  handleNext,
  handleLast,
  currentFilter,
  dateFilters
}) => {
  const [showReports, setShowReports] = useState(false);
  const [showClaps, setShowClaps] = useState(false);
  const [showRecommends, setShowRecommends] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [openConfirmGratitudeModal, setOpenConfirmGratitudeModal] = useState(false);

  const handleConfirmGratitudeModalToggle = () => setOpenConfirmGratitudeModal(prev => !prev);

  const options = {
    custom: true,
    sizePerPage: take,
    totalSize: take * lastPage
  };

  const postIdFormatter = postId => (
    // <Link
    //   className="font-weight-semi-bold"
    //   to={{
    //     pathname: `/post/${postId}`,
    //     state: { currentFilter, dateFilters }
    //   }}
    // >
    //   {postId}
    // </Link>
    <a href={`post/${postId}`} className="font-weight-semi-bold" target="_blank">
      {postId}
    </a>
  );

  const postActionsFormatter = (dataField, { id, status, is_gratitude, lastReport, lastModeration }) => {
    // console.log({ id, status, is_gratitude, lastReport, lastModeration });
    return (
      <Fragment>
        <Modal isOpen={openConfirmGratitudeModal} toggle={handleConfirmGratitudeModalToggle}>
          <ModalHeader toggle={handleConfirmGratitudeModalToggle}>Modal title</ModalHeader>
          <ModalBody>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
            ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
            nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
            anim id est laborum.
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => console.log('Trigerred')}>
              Do Something
            </Button>{' '}
            <Button color="secondary" onClick={handleConfirmGratitudeModalToggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <PostsTableActions
          setOpenConfirmGratitudeModal={setOpenConfirmGratitudeModal}
          postId={id}
          isGratitude={is_gratitude}
          lastReport={lastReport}
          lastModeration={lastModeration}
          status={status}
          setToggleOnAction={setToggleOnAction}
          toggleOnAction={toggleOnAction}
          // filters={filters}
          currentFilter={currentFilter}
          dateFilters={dateFilters}
          setPosts={setPosts}
          post={posts?.find(post => post?.id === id)}
        />
      </Fragment>
    );
  };

  const badgeFormatter = (dataField, { status, lastReport, lastModeration }) => {
    //lastModeration refers to lastUnpassed Moderation
    return (
      <PostStatus
        lastModeration={lastModeration}
        lastReport={lastReport}
        status={status}
        toggleOnAction={toggleOnAction}
      />
    );
  };

  const isGratitudeFormatter = isGratitude => <span>{isGratitude ? 'true' : 'false'}</span>;

  const columns = [
    {
      dataField: 'id',
      text: 'Post Id',
      formatter: postIdFormatter,
      classes: 'border-0 align-middle',
      headerClasses: 'border-0',
      sort: true
    },
    {
      dataField: 'postedBy.firstName',
      text: 'Posted By',
      classes: 'border-0 align-middle',
      headerClasses: 'border-0',
      sort: true,
      formatter: nameFormatter
    },
    {
      dataField: 'posted_date',
      text: 'Posted On',
      formatter: dateFormatter,
      classes: 'border-0 align-middle',
      headerClasses: 'border-0',
      sort: true
    },
    {
      dataField: 'lastReport.reportedDate',
      text: 'Last Reported',
      formatter: dateFormatter,
      classes: 'border-0 align-middle',
      headerClasses: 'border-0',
      sort: true,
      align: 'center'
    },
    {
      dataField: 'lastModeration.createdDate',
      text: 'Last Moderated',
      formatter: dateFormatter,
      classes: 'border-0 align-middle',
      headerClasses: 'border-0',
      sort: true,
      align: 'center'
    },
    {
      dataField: 'lastModeration.moderatedBy',
      text: 'Moderator',
      formatter: moderatedByFormatter,
      classes: 'border-0 align-middle',
      headerClasses: 'border-0',
      sort: true,
      align: 'center'
    },
    {
      dataField: 'status',
      text: 'Status',
      formatter: badgeFormatter,
      classes: 'border-0 align-middle fs-0',
      headerClasses: 'border-0',
      sort: true
    },
    {
      dataField: 'reports.length',
      text: 'Reports',
      classes: 'border-0 align-middle',
      headerClasses: 'border-0',
      sort: true,
      align: 'center',
      formatter: modalFormatter,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          //console.log(row);
          setShowReports(true);
          setCurrentId(row.id);
        }
      }
    },
    {
      dataField: 'recommends',
      text: 'Recommends',
      classes: 'border-0 align-middle',
      sort: true,
      align: 'center',
      formatter: modalFormatter,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setShowRecommends(true);
          setCurrentId(row.id);
        }
      }
    },
    {
      dataField: 'claps',
      text: 'Claps',
      classes: 'border-0 align-middle',
      headerClasses: 'border-0',
      sort: true,
      align: 'center',
      formatter: clapCountFormatter,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setShowClaps(true);
          setCurrentId(row.id);
        }
      }
    },
    {
      dataField: 'is_gratitude',
      text: 'Gratitude',
      classes: 'border-0 align-middle',
      headerClasses: 'border-0',
      formatter: isGratitudeFormatter
    },
    {
      dataField: 'actions',
      classes: 'border-0 align-middle',
      headerClasses: 'border-0',
      text: 'Actions',
      formatter: postActionsFormatter
    }
  ];

  let table = createRef();

  return (
    <Fragment>
      <div className="table-responsive">
        <PostReportsModal modal={showReports} setModal={setShowReports} data={posts} postId={currentId} />
        <ClapCountModal modal={showClaps} setModal={setShowClaps} data={posts} postId={currentId} />
        <RecommendedModal modal={showRecommends} setModal={setShowRecommends} data={posts} postId={currentId} />
        <BootstrapTable
          ref={table}
          bootstrap4
          keyField="id"
          data={posts}
          columns={columns}
          bordered={false}
          classes="table-dashboard table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap"
          rowClasses="btn-reveal-trigger border-top border-200"
          headerClasses="bg-200 text-900 border-y border-200"
        />
      </div>
      <Row noGutters className="px-1 py-3">
        <Col className="pl-3 fs--1">
          <CustomTotal currentPage={currentPage} lastPage={lastPage} />
        </Col>
        <Col xs="auto" className="pr-3">
          <Button
            color={currentPage === 1 ? 'light' : 'primary'}
            size="sm"
            onClick={handleFirst}
            disabled={currentPage === 1}
            className="px-2"
          >
            {'< <'}
          </Button>
          <Button
            color={currentPage === 1 ? 'light' : 'primary'}
            size="sm"
            onClick={handlePrev}
            disabled={currentPage === 1}
            className="px-4 ml-4"
          >
            Previous
          </Button>
          <Button
            color={currentPage >= lastPage ? 'light' : 'primary'}
            // color={'primary'}
            size="sm"
            onClick={handleNext}
            disabled={currentPage >= lastPage}
            className="px-4 ml-2"
          >
            Next
          </Button>
          <Button
            color={currentPage >= lastPage ? 'light' : 'primary'}
            size="sm"
            onClick={handleLast}
            disabled={currentPage >= lastPage}
            className="px-2 ml-4"
          >
            {'> >'}
          </Button>
        </Col>
      </Row>
    </Fragment>
  );
};

export default PostsTable;
