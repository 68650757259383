import React, { useState, useEffect, Fragment } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
  MARK_POST_AS_GRATITUDE_BASE_URL,
  MARK_POST_AS_FLAG_BASE_URL,
  UNPROMOTE_POST_BASE_URL
} from '../../APIEndPoints';
import _ from 'lodash';
import PromotePostModal from './singlepost/PromotePostModal';
const PostTableActions = ({
  post,
  setPosts,
  postId,
  status,
  isGratitude,
  lastReport,
  lastModeration,
  setToggleOnAction,
  toggleOnAction,
  currentFilter,
  dateFilters,
  setOpenConfirmGratitudeModal
}) => {
  const [hasGratitudeAction, setHasGratitudeAction] = useState(false);
  const [hasFlagAction, setHasFlagAction] = useState('none');
  // const [postStatus, setPostStatus] = useState('');
  const token = localStorage.getItem('userToken');
  const [promotePostModalOpen, setPromotePostModalOpen] = useState(false);

  useEffect(() => {
    checkAndUpdateFlagActions();
  }, [toggleOnAction]);

  useEffect(() => {
    if (!isGratitude) setHasGratitudeAction(true);
    else setHasGratitudeAction(false);
  }, [isGratitude, toggleOnAction, hasGratitudeAction]);

  useEffect(() => {
    checkAndUpdateFlagActions();
  }, [status, lastModeration?.id, lastReport?.id]);

  const checkAndUpdateFlagActions = () => {
    // console.log(lastReport);
    // console.log(lastModeration);
    if (!lastReport && !lastModeration) return setHasFlagAction('both');
    if (lastReport && !lastModeration) return setHasFlagAction('both');
    if (!lastReport && lastModeration) {
      const flagAction = status == 'moderated' ? 'green' : 'red';
      return setHasFlagAction(flagAction);
    }

    const lastReportedDate = moment(new Date(lastReport.reportedDate));
    const lastModeratedDate = moment(new Date(lastModeration.createdDate));
    if (lastReportedDate.isAfter(lastModeratedDate)) return setHasFlagAction('both');
    if (lastModeratedDate.isAfter(lastReportedDate)) {
      const flagAction = status == 'moderated' ? 'green' : 'red';
      setHasFlagAction(flagAction);
    }
  };

  const markGratitude = async () => {
    // setOpenConfirmGratitudeModal(true);
    const { data: response } = await axios.post(
      MARK_POST_AS_GRATITUDE_BASE_URL,
      {
        postId: postId,
        isGratitude: true
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    if (response.status === 200) {
      // setHasGratitudeAction(!hasGratitudeAction);
      // setToggleOnAction(!toggleOnAction);
      setPosts(prev => {
        let index = prev?.findIndex(post => post?.id === postId);
        if (index >= 0) {
          let listCopy = _.cloneDeep(prev);
          listCopy[index].is_gratitude = true;
          return listCopy;
        } else {
          return prev;
        }
      });
    }
  };

  const unmarkGratitude = async () => {
    // setOpenConfirmGratitudeModal(true);
    const { data: response } = await axios.post(
      MARK_POST_AS_GRATITUDE_BASE_URL,
      {
        postId: postId,
        isGratitude: false
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    if (response.status === 200) {
      // setHasGratitudeAction(!hasGratitudeAction);
      // setToggleOnAction(!toggleOnAction);
      setPosts(prev => {
        let index = prev?.findIndex(post => post?.id === postId);
        if (index >= 0) {
          let listCopy = _.cloneDeep(prev);
          listCopy[index].is_gratitude = false;
          return listCopy;
        } else {
          return prev;
        }
      });
    }
  };

  const greenFlagPost = async () => {
    // console.log(postId);
    const { data: response } = await axios.post(
      MARK_POST_AS_FLAG_BASE_URL,
      {
        postId: postId,
        flag: 'green'
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    if (response.status === 200) {
      setHasFlagAction('red');
      setToggleOnAction(!toggleOnAction);
    }
  };

  const redFlagPost = async () => {
    // console.log(postId);
    const { data: response } = await axios.post(
      MARK_POST_AS_FLAG_BASE_URL,
      {
        postId: postId,
        flag: 'red'
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    if (response.status === 200) {
      setHasFlagAction('green');
      setToggleOnAction(!toggleOnAction);
    }
  };

  const unpromotePost = async () => {
    const { data: response } = await axios.post(
      UNPROMOTE_POST_BASE_URL.replace('##id##', postId),
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    if (response.status === 200) {
      // setPost(prev => {
      //   let copy = _.cloneDeep(prev);
      //   copy.isPromoted = false;
      //   return prev;
      // });
      setPosts(prev => {
        let index = prev?.findIndex(post => post?.id === postId);
        console.log('index', post, index);
        if (index >= 0) {
          let listCopy = _.cloneDeep(prev);
          console.log('index', listCopy[index]);
          listCopy[index].is_promoted = false;
          console.log('index', listCopy[index]);
          return listCopy;
        } else {
          return prev;
        }
      });
    }
  };

  const handlePromotePostModalOpen = () => {
    setPromotePostModalOpen(true);
  };

  const closePromotePostModal = () => {
    setPromotePostModalOpen(false);
  };

  const updatePromoteFieldInPost = () => {
    setPosts(prev => {
      let index = prev?.findIndex(post => post?.id === postId);
      console.log('index', index);
      if (index >= 0) {
        let listCopy = _.cloneDeep(prev);
        console.log('index', listCopy[index]);
        listCopy[index].is_promoted = true;
        console.log('index', listCopy[index]);

        return listCopy;
      } else {
        return prev;
      }
    });
  };

  return (
    <UncontrolledDropdown>
      {promotePostModalOpen && (
        <PromotePostModal
          postId={postId}
          closePromotePostModal={closePromotePostModal}
          updatePromoteFieldInPost={updatePromoteFieldInPost}
        />
      )}
      <DropdownToggle color="link" size="sm" className="text-600 btn-reveal">
        <FontAwesomeIcon icon="ellipsis-h" className="fs--1" />
      </DropdownToggle>
      <DropdownMenu right className="border py-2">
        {!post?.is_promoted && <DropdownItem onClick={handlePromotePostModalOpen}>Promote Post</DropdownItem>}
        {post?.is_promoted && <DropdownItem onClick={unpromotePost}>unPromote Post</DropdownItem>}

        {!isGratitude ? <DropdownItem onClick={markGratitude}>Mark as Gratitude</DropdownItem> : ''}
        {isGratitude ? <DropdownItem onClick={unmarkGratitude}>Unmark Gratitude</DropdownItem> : ''}

        {hasFlagAction === 'both' && (
          <Fragment>
            <DropdownItem onClick={redFlagPost}>Red Flag</DropdownItem>
            <DropdownItem onClick={greenFlagPost}>Green Flag</DropdownItem>
          </Fragment>
        )}

        {hasFlagAction === 'red' && <DropdownItem onClick={redFlagPost}>Red Flag</DropdownItem>}

        {hasFlagAction === 'green' && <DropdownItem onClick={greenFlagPost}>Green Flag</DropdownItem>}

        <DropdownItem>
          <Link
            to={{
              pathname: `/post/${postId}`,
              state: { currentFilter, dateFilters }
            }}
          >
            View this post
          </Link>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default PostTableActions;
