import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Badge from 'reactstrap/es/Badge';

function PostStatus({ lastReport, lastModeration, status, toggleOnAction, setShowModerations, setShowReports }) {
  const [color, setColor] = useState('');
  const [icon, setIcon] = useState('');
  const [text, setText] = useState('');

  const updateStatus = () => {
    if (status == 'moderation-error') {
      setColor('secondary');
      setIcon('ban');
      setText('Moderation Error');
    } else if (status == 'moderated') {
      setColor('danger');
      setIcon('ban');
      setText('Red Flagged');
      return;
    } else if (status == 'active') {
      if (lastModeration && !lastReport) {
        setColor('success');
        setIcon('check');
        setText('Green Flagged');
      } else if (!lastModeration && lastReport) {
        setColor('warning');
        setIcon('ban');
        setText('Reported');
      } else if (lastModeration && lastReport) {
        const lastModeratedDate = moment(new Date(lastModeration.createdDate));
        const lastReportedDate = moment(new Date(lastReport.reportedDate));
        if (lastReportedDate.isAfter(lastModeratedDate)) {
          setColor('warning');
          setIcon('ban');
          setText('Reported');
        } else {
          setColor('success');
          setIcon('check');
          setText('Green Flagged');
        }
      } else {
        setColor('success');
        setIcon('check');
        setText('Active');
      }
    }
  };

  useEffect(() => {
    updateStatus();
  }, [status]);

  useEffect(() => {
    updateStatus();
  }, [toggleOnAction]);

  const handleBadgeClick = () => {
    if (!setShowModerations || !setShowReports) return;
    if (text == 'Red Flagged' || text == 'Green Flagged') setShowModerations(true);
    else if (text == 'Reported') setShowReports(true);
  };
  return (
    <Fragment>
      <Badge
        color={`soft-${color}`}
        className="rounded-capsule"
        style={{ cursor: 'pointer', marginRight: '10px' }}
        onClick={handleBadgeClick}
      >
        {text}
        {/* <FontAwesomeIcon icon={icon} transform="shrink-2" className="ml-1" /> */}
      </Badge>
    </Fragment>
  );
}

export default PostStatus;
