import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import RecommendedTable from './RecommendedTable';
import axios from 'axios';
import { GET_SINGLE_POST_BASE_URL } from '../../APIEndPoints';

const RecommendedModal = props => {
  const { className, postId, setModal, modal } = props;
  const token = localStorage.getItem('userToken');

  //   const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const [details, setDetails] = useState([]);

  // const getUserFieldDetails = post => {
  //   const details = post ? targetPost.recommends : '';
  //   setDetails(details);
  // };

  const _getPostWithRelationById = async id => {
    const { data: result } = await axios.get(`${GET_SINGLE_POST_BASE_URL}?postId=${id}&relation=recommends`,
     {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    // console.log(result);
    return result?.data?.recommends;
  }

  useEffect(() => {
    if(postId)
      _getPostWithRelationById(postId)
      .then(recommends => recommends ? setDetails(recommends) : setDetails(''))
      .catch(err => console.log(err));
  }, [postId, modal]);

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>Recommends Summary</ModalHeader>
        <ModalBody>
          <RecommendedTable recommends={details} />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default RecommendedModal;
