import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReportsTable from './ReportsTable';

const PostReportsModal = props => {
  const { className, data, postId, setModal, modal, currentPost } = props;

  //console.log(data);
  //console.log(postId);

  //   const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const [details, setDetails] = useState([]);

  const getPostFieldDetails = postId => {
    if(currentPost) {
      const details = currentPost.reports ? currentPost.reports : '';
      setDetails(details);
    }
    else {
      const targetPost = data?.find(post => post.id == postId);
      const details = targetPost ? targetPost.reports : '';
      setDetails(details);
    }
  };

  useEffect(() => {
    getPostFieldDetails(postId);
  }, [postId, modal]);

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>Reports Summary</ModalHeader>
        <ModalBody>
          <ReportsTable reports={details} />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default PostReportsModal;
