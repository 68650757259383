import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, CustomInput, NavItem, Nav, FormGroup, Label } from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import axios from 'axios';
import PostsTable from './PostsTable';
import { isIterableArray } from '../../helpers/utils';
import Datetime from 'react-datetime';
import moment from 'moment';
import { GET_POSTS_BASE_URL } from '../../APIEndPoints';

const Dashboard = ({ history, match, location }) => {
  const [posts, setPosts] = useState([]);
  const [toggleOnAction, setToggleOnAction] = useState(false);
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [toggleOnFiltersChange, setToggleOnFiltersChange] = useState(false);
  const token = localStorage.getItem('userToken');
  const [prevFilter, setPrevFilter] = useState(
    location && location.state && location.state.currentFilter ? location.state.currentFilter : null
  );
  const [prevDateFilters, setPrevDateFilters] = useState(
    location && location.state && location.state.dateFilters ? location.state.dateFilters : null
  );
  const [currentFilter, setCurrentFilter] = useState(false);
  const [postedDateFilter, setPostedDateFilter] = useState(false);
  const [sortBy, setSortBy] = useState('none');
  const [sortOrder, setSortOrder] = useState('desc');
  // const [filters, setFilters] = useState({
  //   postedDates: false,
  //   reported: false,
  //   recommended: false,
  //   gratitude: false,
  //   redFlagged: false,
  //   greenFlagged: false
  // });

  const [dateFilters, setDateFilters] = useState({
    fromDate: '',
    toDate: '',
    reportedFromDate: '',
    reportedToDate: ''
  });

  const filterNames = {
    all: 'All Posts',
    gratitude: 'Gratitude Posts',
    recommended: 'Recommended Posts',
    reported: 'Reported Posts',
    redFlagged: 'Red Flagged Posts',
    greenFlagged: 'Green Flagged Posts',
    moderationErrored: 'Moderation Errored Posts',
    promoted: 'Promoted'
  };

  // const handleFilters = e => {
  //   const newFilters = { ...filters, [e.target.name]: e.target.checked };
  //   setFilters(newFilters);
  //   setToggleOnFiltersChange(!toggleOnFiltersChange);

  //   // console.log(newFilters);
  // };

  const handleCurrentFilter = e => {
    if (e.target.value == 'all') setCurrentFilter(false);
    const { reportedFromDate, reportedToDate } = dateFilters;
    if (e.target.value != 'reported' && (reportedFromDate || reportedToDate)) {
      const newFilters = { ...dateFilters, reportedFromDate: '', reportedToDate: '' };
      setDateFilters(newFilters);
      setToggleOnFiltersChange(!toggleOnFiltersChange);
    }
    setCurrentFilter(e.target.value);
    // console.log(e.target.value);
  };

  const handleDateFilters = ({ name, value }) => {
    const newFilters = { ...dateFilters, [name]: value };
    setDateFilters(newFilters);
    setToggleOnFiltersChange(!toggleOnFiltersChange);
    // console.log(newFilters);
  };

  const handlePostedDateFilterChecked = e => {
    setPostedDateFilter(!postedDateFilter);
    // console.log(e.target.checked);
    if (e.target.checked == false) {
      const newFilters = { ...dateFilters, fromDate: '', toDate: '' };
      setDateFilters(newFilters);
      setToggleOnFiltersChange(!toggleOnFiltersChange);
    }
  };

  const handleSortBy = e => setSortBy(e.target.value);

  const handleSortOrder = e => setSortOrder(e.target.value);

  const _setQueryParams = (filter, dateFilters, sortBy) => {
    const queryParams = {};
    if (
      filter == 'gratitude' ||
      filter == 'recommended' ||
      filter == 'reported' ||
      filter == 'moderationErrored' ||
      filter === 'promoted'
    )
      queryParams[filter] = true;
    else if (filter == 'redFlagged') queryParams.flagged = 'red';
    else if (filter == 'greenFlagged') queryParams.flagged = 'green';
    if (dateFilters) {
      for (const opt in dateFilters) {
        if (dateFilters[opt]) queryParams[opt] = dateFilters[opt];
      }
    }
    if (sortBy == 'none') {
      if (queryParams.sortBy) delete queryParams.sortBy;
    } else queryParams.sortBy = sortBy;
    queryParams.sortOrder = sortOrder;
    queryParams.skip = skip;
    queryParams.take = take;
    // console.log(queryParams);
    return queryParams;
  };

  const _getPosts = async (filter, dateFilters, sortBy, sortOrder) => {
    const queryParams = _setQueryParams(filter, dateFilters, sortBy, sortOrder);
    const { data: response } = await axios.get(GET_POSTS_BASE_URL, {
      params: queryParams,
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    // console.log(GET_POSTS_BASE_URL, queryParams);
    const fetchedPosts = response.data
      ? response.data.map(post => ({
          ...post,
          actions: {
            postId: post.id,
            isGratitude: post.isGratitude,
            lastReport: post.lastReport,
            lastReview: post.lastReview
          }
        }))
      : [];
    //app.updeed.co/gratitudewall
    setPosts(fetchedPosts);
    setLastPage(response.lastPage > 0 ? response.lastPage : 1);
  };

  useEffect(() => {
    _getPosts(currentFilter, dateFilters, sortBy, sortOrder);
  }, [currentPage, take, toggleOnAction, currentFilter, dateFilters, sortBy, sortOrder]);

  useEffect(() => {
    handleFirst();
  }, [toggleOnFiltersChange, sortBy, sortOrder]);

  useEffect(() => {
    if (prevFilter) {
      setCurrentFilter(prevFilter);
    }

    if (prevDateFilters) {
      setDateFilters(prevDateFilters);
    }
    if (prevDateFilters || prevFilter) {
      _getPosts(prevFilter, prevDateFilters, sortBy, sortOrder);
    }
  }, [prevFilter, prevDateFilters, sortBy, sortOrder]);

  const handleFirst = () => {
    setSkip(0);
    setCurrentPage(1);
  };
  const handleNext = () => {
    if (currentPage <= lastPage) {
      const nextPage = currentPage + 1;
      setSkip((nextPage - 1) * take);
      setCurrentPage(nextPage);
    }
  };

  const handlePrev = async () => {
    const prevPage = currentPage - 1;
    if (prevPage >= 1) {
      setSkip((prevPage - 1) * take);
      setCurrentPage(prevPage);
    }
  };

  const handleLast = () => {
    setSkip((lastPage - 1) * 10);
    setCurrentPage(lastPage);
  };

  return (
    <Row className="mt-2" noGutters>
      <Col md={2} className="col-xxl-3 col-md-2 col-sm-3 mb-3 pl-md-2 pr-md-2">
        <FalconCardHeader title="Filter By" light={false}>
          <Nav className="mt-4 mb-1" navbar vertical>
            <FormGroup>
              <NavItem>
                <CustomInput
                  id="postFiltersPage"
                  type="select"
                  style={{ width: '180px', fontSize: '14px' }}
                  value={currentFilter}
                  onChange={handleCurrentFilter}
                >
                  {isIterableArray(Object.keys(filterNames)) &&
                    Object.keys(filterNames).map((filter, index) => (
                      <option key={index} value={filter}>
                        {filterNames[filter]}
                      </option>
                    ))}
                </CustomInput>
              </NavItem>
              {currentFilter == 'reported' && (
                <NavItem>
                  <FormGroup>
                    <Label className="fs--1 mt-4" for="reportedFromDate">
                      Reported From
                    </Label>
                    <Datetime
                      timeFormat={false}
                      value={dateFilters['reportedFromDate']}
                      disabled={currentFilter == 'reported'}
                      onChange={dateTime => {
                        const date = {};
                        date.name = 'reportedFromDate';
                        if (dateTime._isValid) date.value = dateTime.format('DD-MM-YYYY');
                        else date.value = '';
                        handleDateFilters(date);
                      }}
                      dateFormat="DD-MM-YYYY"
                      inputProps={{ placeholder: 'DD-MM-YYYY', id: 'reportedFromDate' }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="fs--1" for="reportedToDate">
                      Reported Upto
                    </Label>
                    <Datetime
                      timeFormat={false}
                      value={dateFilters['reportedToDate']}
                      isValidDate={current => {
                        const startDate = moment(dateFilters['reportedFromDate'], 'DD-MM-YYYY');
                        const now = moment(new Date());
                        return current.isSameOrAfter(startDate) && current.isSameOrBefore(now);
                      }}
                      onChange={dateTime => {
                        const date = {};
                        date.name = 'reportedToDate';
                        if (dateTime._isValid) date.value = dateTime.format('DD-MM-YYYY');
                        else date.value = '';
                        handleDateFilters(date);
                      }}
                      dateFormat="DD-MM-YYYY"
                      inputProps={{ placeholder: 'DD-MM-YYYY', id: 'reportedToDate' }}
                    />
                  </FormGroup>
                </NavItem>
              )}
              <NavItem>
                <CustomInput
                  className="mt-3"
                  type="checkbox"
                  id={'postedDateFilter'}
                  label={
                    <span className="mr-3" height="25">
                      Posted Dates
                    </span>
                  }
                  checked={postedDateFilter}
                  name={'postedDateFilter'}
                  onChange={handlePostedDateFilterChecked}
                />
              </NavItem>
            </FormGroup>
            {postedDateFilter && (
              <Fragment>
                <FormGroup>
                  <Label className="fs--1" for="fromDate">
                    Posted From
                  </Label>
                  <Datetime
                    timeFormat={false}
                    value={dateFilters['fromDate']}
                    onChange={dateTime => {
                      const date = {};
                      date.name = 'fromDate';
                      if (dateTime._isValid) date.value = dateTime.format('DD-MM-YYYY');
                      else date.value = '';
                      handleDateFilters(date);
                    }}
                    dateFormat="DD-MM-YYYY"
                    inputProps={{ placeholder: 'DD-MM-YYYY', id: 'fromDate' }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label className="fs--1" for="toDate">
                    Posted Upto
                  </Label>
                  <Datetime
                    timeFormat={false}
                    value={dateFilters['toDate']}
                    disabled={dateFilters['fromDate']}
                    isValidDate={current => {
                      const startDate = moment(dateFilters['fromDate'], 'DD-MM-YYYY');
                      const now = moment(new Date());
                      return current.isSameOrAfter(startDate) && current.isSameOrBefore(now);
                    }}
                    onChange={dateTime => {
                      const date = {};
                      date.name = 'toDate';
                      if (dateTime._isValid) date.value = dateTime.format('DD-MM-YYYY');
                      else date.value = '';
                      handleDateFilters(date);
                    }}
                    dateFormat="DD-MM-YYYY"
                    inputProps={{ placeholder: 'DD-MM-YYYY', id: 'toDate' }}
                  />
                </FormGroup>
              </Fragment>
            )}
            <FormGroup>
              <h4 className="fs-0">Sort By</h4>
              <NavItem>
                <CustomInput
                  id="sortFiltersPage"
                  type="select"
                  style={{ width: '180px', fontSize: '14px' }}
                  value={sortBy}
                  onChange={handleSortBy}
                >
                  <option value={'none'}>Default</option>
                  <option value={'id'}>Post Id</option>
                  <option value={'recommends'}>Recommend Count</option>
                  <option value={'claps'}>Clap Count</option>
                  <option value={'reports'}>Report Count</option>
                </CustomInput>
              </NavItem>
            </FormGroup>
            <FormGroup>
              <h4 className="fs-0">Sort Order</h4>
              <NavItem>
                <CustomInput
                  id="sortFiltersPage"
                  type="select"
                  style={{ width: '180px', fontSize: '14px' }}
                  value={sortOrder}
                  onChange={handleSortOrder}
                >
                  <option value={'desc'}>Desc</option>
                  <option value={'asc'}>Asc</option>
                </CustomInput>
              </NavItem>
            </FormGroup>
          </Nav>
        </FalconCardHeader>
      </Col>
      <Col md={10} className="col-xxl-9 col-md-2 col-sm-3 mb-3 pl-md-2 pr-md-2">
        <Card className="mb-3">
          <FalconCardHeader title="Posts Summary" light={false}>
            <FormGroup>
              <CustomInput
                id="postRecordsPerPage"
                type="select"
                style={{ width: '150px' }}
                value={take}
                onChange={e => setTake(e.target.value)}
              >
                <option value="25"> 25 records</option>
                <option value="50"> 50 records</option>
                <option value="100">100 records</option>
              </CustomInput>
            </FormGroup>
          </FalconCardHeader>
          <CardBody className="p-0">
            <PostsTable
              posts={posts}
              setPosts={setPosts}
              skip={skip}
              take={take}
              currentPage={currentPage}
              lastPage={lastPage}
              handleFirst={handleFirst}
              handlePrev={handlePrev}
              handleNext={handleNext}
              handleLast={handleLast}
              setToggleOnAction={setToggleOnAction}
              toggleOnAction={toggleOnAction}
              currentFilter={currentFilter}
              // filters={filters}
              dateFilters={dateFilters}
              history={history}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default Dashboard;
